import classNames from "classnames";

const Banner = ({ children, prose = false }) => (
  <div className="bg-dark-purple p-3.5 opacity-90">
    <div
      className={classNames(
        prose && "prose",
        "text-center text-sm mx-auto whitespace-pre-line",
        "text-white font-medium"
      )}
    >
      {children}
    </div>
  </div>
);

const MAINTENANCE_WINDOW = "Wednesday, 30 Oct, from 10pm to 11pm SGT";

export const MaintenanceMessage = ({ concise = false }) => (
  <p>
    We&apos;re upgrading our systems to enhance reliability and will undergo
    scheduled maintenance on{" "}
    <span className="font-bold">{MAINTENANCE_WINDOW}</span>.
    {!concise && (
      <>
        {" "}
        Our website will be temporarily unavailable during this time. We
        appreciate your understanding as we work to serve you better.
      </>
    )}
  </p>
);

export const MaintenanceBanner = ({ concise = false }) => (
  <Banner prose={true}>
    <MaintenanceMessage concise={concise} />
  </Banner>
);

export const OfficeRelocationMessage = () => (
  <div>
    <div>
      We are moving! From <span className="font-bold">15 Nov 2024</span>, our
      address will be{" "}
      <span className="font-bold">
        18 Howard Road, Novelty BizCentre, #07-02.
      </span>
    </div>
    <div>
      Please note that visits are by appointment only. View{" "}
      <a
        className="underline text-inherit text-sm font-medium"
        href="https://support.justship.sg/en/article/may-i-come-down-to-your-warehouse-to-drop-off-my-parcel-5u2nus"
        target="_blank"
        rel="noreferrer"
      >
        here
      </a>{" "}
      for more info.
    </div>
  </div>
);

export const OfficeRelocationBanner = () => (
  <Banner>
    <OfficeRelocationMessage />
  </Banner>
);

export default Banner;
