import { useState } from "react";
import classNames from "classnames";
import { LeftChevronSVG, RightChevronSVG } from "@/components/svg";
import { useEffect } from "react";
import SegmentGenerator from "@/components/segment-generator";

export const Carousel = ({ segments, autoSlide = false }) => {
  const [activeHero, setActiveHero] = useState(0);
  const [transitioning, setTransitioning] = useState(false);
  const [transitionDirection, setTransitionDirection] = useState("");

  const handleNextHero = () => {
    if (!transitioning) {
      setActiveHero((activeHero + 1) % segments.length);
      setSlideTransitioning("next");
    }
  };
  const handlePreviousHero = () => {
    if (!transitioning) {
      setActiveHero((activeHero - 1 + segments.length) % segments.length);
      setSlideTransitioning("previous");
    }
  };

  const setSlideTransitioning = (direction) => {
    setTransitionDirection(direction);
    setTransitioning(true);
    setTimeout(() => {
      setTransitioning(false);
    }, 700);
  };

  useEffect(() => {
    const setNextHero = () => {
      setActiveHero((activeHero + 1) % segments.length);
    };
    if (autoSlide) {
      const interval = setInterval(setNextHero, 8000);
      return () => clearInterval(interval);
    }
  }, [activeHero, segments, autoSlide]);

  return (
    <div className="relative h-fit overflow-hidden">
      {segments.map((segment, index) => {
        const isActive = index === activeHero;
        const isPrevious =
          index === (activeHero - 1 + segments.length) % segments.length;
        const isNext = index === (activeHero + 1) % segments.length;
        return (
          <div
            key={segment._id}
            className={classNames(
              "absolute inset-0 ",
              "transition-transform ease-in-out duration-700 transform",
              {
                "-translate-x-full": isPrevious,
                "translate-x-full": isNext,
                "translate-x-0": isActive,
              },
              {
                "z-10":
                  isActive ||
                  (isPrevious && transitionDirection === "next") ||
                  (isNext && transitionDirection === "previous"),
              }
            )}
          >
            <SegmentGenerator segment={segment} />
          </div>
        );
      })}
      {/* for setting the height of the carousel */}
      <div className="invisible">
        <SegmentGenerator segment={segments[segments.length - 1]} />
      </div>
      <button
        className={classNames(
          "absolute top-[calc(50%-21px)] left-1 sm:left-2 rounded z-20",
          "sm:bg-white/30 hover:bg-white/40 active:bg-white/50",
          "text-subtext-gray sm:text-subheading-gray hover:text-dark-purple sm:opacity-50 hover:opacity-100",
          "transition-all ease duration-300"
        )}
        onClick={handlePreviousHero}
        aria-label="Previous hero"
      >
        <LeftChevronSVG className="h-[42px] w-[24px] md:w-[30px]" />
      </button>
      <button
        className={classNames(
          "absolute top-[calc(50%-21px)] right-1 sm:right-2 rounded z-20",
          "sm:bg-white/30 hover:bg-white/40 active:bg-white/50",
          "text-subtext-gray sm:text-subheading-gray hover:text-dark-purple sm:opacity-50 hover:opacity-100",
          "transition-all ease duration-300"
        )}
        onClick={handleNextHero}
        aria-label="Next hero"
      >
        <RightChevronSVG className="h-[42px] w-[24px] md:w-[30px]" />
      </button>
    </div>
  );
};
