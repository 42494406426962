import dynamic from "next/dynamic";
import { urlForImage } from "@/lib/sanity";
import classNames from "classnames";

const StandardSingleBlock = dynamic(() =>
  import("@/components/segments/standard-single-block")
);
const HeroSingleBlock = dynamic(() =>
  import("@/components/segments/hero-single-block")
);
const HeroSingleBlockHomePage = dynamic(() =>
  import("@/components/segments/hero-single-block-home-page")
);
const HeroCountries = dynamic(() =>
  import("@/components/segments/hero-countries")
);
const TwoColumnsSingleBlock = dynamic(() =>
  import("@/components/segments/two-columns-single-block")
);
const TwoColumnsTwoBlocks = dynamic(() =>
  import("@/components/segments/two-columns-two-blocks")
);
const MultiColumnWithHorizontalLines = dynamic(() =>
  import("@/components/segments/multi-column-with-horizontal-lines")
);
const ListRows = dynamic(() => import("@/components/segments/list-rows"));
const NumberedListRows = dynamic(() =>
  import("@/components/segments/numbered-list-rows")
);
const NumberedListLargeNumbersLeftRows = dynamic(() =>
  import("@/components/segments/numbered-list-large-numbers-left-rows")
);
const ImageCaptionHoverTextListRows = dynamic(() =>
  import("@/components/segments/image-caption-hover-text-list-rows")
);
const VerticalSteps = dynamic(() =>
  import("@/components/segments/vertical-steps")
);
const HorizontalSteps = dynamic(() =>
  import("@/components/segments/horizontal-steps")
);
const StackedListPanelTwoColumnBlocks = dynamic(() =>
  import("@/components/segments/stacked-list-panel-two-column-blocks")
);
const GoogleReviewsLayout = dynamic(() =>
  import("@/components/segments/google-reviews-layout")
);

export default function SegmentGenerator({ segment }) {
  const props = getSegmentProps(segment);
  if (segment.layout === "standard-single-block") {
    return <StandardSingleBlock {...props} />;
  } else if (segment.layout === "hero-single-block") {
    return <HeroSingleBlock {...props} />;
  } else if (segment.layout === "hero-single-block-home-page") {
    return <HeroSingleBlockHomePage {...props} />;
  } else if (segment.layout === "hero-countries-single-block") {
    return <HeroCountries {...props} />;
  } else if (segment.layout === "two-columns-single-block") {
    return <TwoColumnsSingleBlock {...props} />;
  } else if (segment.layout === "two-columns-two-blocks") {
    return <TwoColumnsTwoBlocks {...props} />;
  } else if (segment.layout === "list-rows") {
    return <ListRows {...props} />;
  } else if (segment.layout === "toggle-list-rows") {
    return <ListRows {...props} toggle={true} />;
  } else if (segment.layout === "numbered-list-rows") {
    return <NumberedListRows {...props} />;
  } else if (segment.layout === "numbered-list-large-numbers-left-rows") {
    return <NumberedListLargeNumbersLeftRows {...props} />;
  } else if (segment.layout === "image-caption-hover-text-list-rows") {
    return <ImageCaptionHoverTextListRows {...props} />;
  } else if (segment.layout === "multi-column-with-horizontal-lines") {
    return <MultiColumnWithHorizontalLines {...props} />;
  } else if (segment.layout === "vertical-steps") {
    return <VerticalSteps {...props} />;
  } else if (segment.layout === "horizontal-steps") {
    return <HorizontalSteps {...props} />;
  } else if (segment.layout === "stacked-list-panel-two-column-blocks") {
    return <StackedListPanelTwoColumnBlocks {...props} />;
  } else if (segment.layout === "google-reviews-layout") {
    return <GoogleReviewsLayout {...props} />;
  } else {
    return (
      <div className="max-w-6xl m-auto p-12 border border-pink">
        <p>
          No segment defined for: {segment.layout} {segment.name}
        </p>
      </div>
    );
  }
}

export const getSegmentProps = (segment) => {
  const widthClasses = getWidthClasses(segment.width);
  const paddingClasses = getPaddingClasses(
    segment.topPadding,
    segment.bottomPadding
  );
  const bgColorStyle = segment.bgColorHex && {
    backgroundColor: segment.bgColorHex,
  };
  const bgImageStyle = segment.bgImage && {
    backgroundImage: `url(${urlForImage(segment.bgImage)})`,
    backgroundPosition: segment.bgImagePosition || "center",
  };
  const bgStyle = { ...bgColorStyle, ...bgImageStyle };
  return {
    segment,
    widthClasses,
    paddingClasses,
    headingSizeClasses,
    gridColsClasses,
    bgColorStyle,
    bgImageStyle,
  };
};

const getWidthClasses = (width) => {
  const widthClasses = {
    full: "w-full",
    "four-fifths": "w-full md:w-4/5",
    "three-quarters": "w-full md:w-3/4",
    "two-thirds": "w-full md:w-2/3",
    golden: "w-full md:w-[61.8%]",
    half: "w-full md:w-1/2",
  };
  return widthClasses[width] || "";
};

const getPaddingClasses = (topPadding, bottomPadding) => {
  const topPaddingClasses = {
    none: "",
    "x-small": "pt-2 md:pt-3",
    small: "pt-4 md:pt-6",
    medium: "pt-8 md:pt-12",
    large: "pt-12 md:pt-16",
    "x-large": "pt-16 md:pt-24",
    "xx-large": "pt-24 md:pt-32",
    "xxx-large": "pt-32 md:pt-44",
  };
  // we have to be verbose and repeat explicitly because of how tailwind works
  const bottomPaddingClasses = {
    none: "",
    "x-small": "pb-2 md:pb-3",
    small: "pb-4 md:pb-6",
    medium: "pb-8 md:pb-12",
    large: "pb-12 md:pb-16",
    "x-large": "pb-16 md:pb-24",
    "xx-large": "pb-24 md:pb-32",
    "xxx-large": "pb-32 md:pb-44",
  };
  return classNames(
    topPaddingClasses[topPadding],
    bottomPaddingClasses[bottomPadding]
  );
};

const headingSizeClasses = {
  xs: "text-xs",
  vsm: "text-xs sm:text-vsm",
  sm: "text-vsm sm:text-sm",
  base: "text-sm sm:text-base",
  lg: "text-base sm:text-lg",
  xl: "text-lg sm:text-xl",
  "2xl": "text-xl sm:text-2xl",
  "3xl": "text-2xl sm:text-3xl",
  "4xl": "text-3xl sm:text-4xl",
  "4.5xl": "text-3xl sm:text-4.5xl",
  "5xl": "text-4xl sm:text-5xl",
  "6xl": "text-5xl sm:text-6xl",
  "7xl": "text-6xl sm:text-7xl",
  "8xl": "text-7xl sm:text-8xl",
};

const gridColsClasses = {
  1: "grid-cols-1",
  2: "grid-cols-1 md:grid-cols-2",
  3: "grid-cols-1 sm:grid-cols-3",
  4: "grid-cols-1 sm:grid-cols-3 md:grid-cols-4",
  5: "grid-cols-2 sm:grid-cols-3 md:grid-cols-5",
  6: "grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6",
  7: "grid-cols-2 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-7",
  8: "grid-cols-2 sm:grid-cols-4 md:grid-cols-6 lg:grid-cols-8",
  9: "grid-cols-3 sm:grid-cols-5 md:grid-cols-7 lg:grid-cols-9",
  10: "grid-cols-4 sm:grid-cols-6 md:grid-cols-8 lg:grid-cols-10",
};
